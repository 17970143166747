import { React, useEffect } from 'react';
import Header from './Components/Header/Header';
import Section01 from './Components/Section01/Section01';
import Section02 from './Components/Section02/Section02';
import Section03 from './Components/Section03/Section03';
import Section04 from './Components/Section04/Section04';
import Section05 from './Components/Section05/Section05';
import Section06 from './Components/Section06/Section06';
import Section07 from './Components/Section07/Section07';
import Section08 from './Components/Section08/Section08';
import Section09 from './Components/Section09/Section09';
import Section10 from './Components/Section10/Section10';
import Section11 from './Components/Section11/Section11';
import Section12 from './Components/Section12/Section12';
import Footer from './Components/Footer/Footer';
import './css/default.css';
import './App.css';
import AOS from "aos";
import "aos/dist/aos.css";
function App() {
  useEffect(() => {
    AOS.init();
  })
    return (
    <div className="App">
      {/* header */}
      <Header/>
      {/* section 01 */}
      <Section01/>
      {/* section 02 */}
      <Section02/>
      {/* section 03 */}
      <Section03/>
      {/* section 04 */}
      <Section04/>
      {/* section 05 */}
      <Section05/>
      {/* section 06 */}
      <Section06/>
      {/* section 07 */}
      <Section07/>
      {/* section 08 */}
      <Section08/>
      {/* section 09 */}
      <Section09/>
      {/* section 10 */}
      <Section10/>
      {/* section 11 */}
      <Section11/>
      {/* section 12 */}
      <Section12/>
      {/* Footer */}
      <Footer/>
    </div>
  );
}

export default App;
