import { React } from 'react';
import { footer_logo } from '../../export/export';
import './footer.css';

function Footer() {
    return (
        <>
            {/* Web Layout */}
            <div className='footer web-layout padding-2 display-flex' style={{ backgroundColor: 'black'}}>
                <div className='width-100'>
                    <div className='content01-inner-box width-30 height-100 margin-auto text-left position-relative padding-top-05'>
                        <img className='footer-logo-img' src={footer_logo} />
                        <p className='content01-text text-white margin-bottom-0 position-absolute bottom-0'>Copyright 2022. HICOIN All Rights Reserved</p>
                    </div>
                </div>
                <div className='width-100'>
                    <div className='content02-inner-box width-30 margin-auto text-left'>
                        <p className='content02-title text-white font-bold font-24'>CONTACT</p>
                        <p className='content02-text text-white'>hicoin2022@gmail.com</p>
                        <p className='content02-text text-white'><a className='footer-sns-link' onClick={() => window.open('https://t.me/officialhicoin', '_blank')}>Telegram</a></p>
                        <p className='content02-text text-white'><a className='footer-sns-link' onClick={() => window.open('https://medium.com/@hicoin2022', '_blank')}>Medium</a></p>
                        <p className='content02-text text-white margin-bottom-0'><a className='footer-sns-link' onClick={() => window.open('https://twitter.com/HicoinOfficial', '_blank')}>Twitter</a></p>
                    </div>
                </div>
            </div>
            {/* Mibile Layout */}
            <div className='mobile-layout' style={{ backgroundColor: 'black'}}>
                <img className='footer-logo-img' src={footer_logo} />
                <p className='content02-title text-white font-bold font-24'>CONTACT</p>
                <p className='content02-text text-white'>hicoin2022@gmail.com</p>
                <p className='content02-text text-white'><a className='footer-sns-link' onClick={() => window.open('https://t.me/officialhicoin', '_blank')}>Telegram</a></p>
                <p className='content02-text text-white'><a className='footer-sns-link' onClick={() => window.open('https://medium.com/@hicoin2022', '_blank')}>Medium</a></p>
                <p className='content02-text text-white margin-bottom-2'><a className='footer-sns-link' onClick={() => window.open('https://twitter.com/HicoinOfficial', '_blank')}>Twitter</a></p>
                <p className='content01-text text-white margin-bottom-0'>Copyright 2022. HICOIN All Rights Reserved</p>
            </div>
        </>
    )
}

export default Footer