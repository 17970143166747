import { React } from 'react';
import { useTranslation } from 'react-i18next';
import '../../css/default.css';
import './section07.css';
import '../../App.css';

function Section07() {
    const { t } = useTranslation();

    return (
        <>
        <div className='section07' id='07' data-aos="fade-up" data-aos-duration="2300">
            <div className='max-width-1400 margin-auto'>
                <div className='section01-back-box position-relative'>
                    <div className='section06-back width-100 position-relative' />
                    <div className='position-absolute'>
                        <h1 className='sec07-title text-yellow font-weight-bold font-40 margin-bottom-2'>{t('section07.title')}</h1>
                        <h3 className='sec07-content font-20 font-weight-6'>{t('section07.text01')}</h3>
                        <h3 className='sec07-content font-20 font-weight-6'>{t('section07.text02')}</h3>
                        <h3 className='sec07-content font-20 font-weight-6'>{t('section07.text03')}</h3>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Section07