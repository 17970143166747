import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { sec07_icon01, sec07_icon02, sec07_icon03} from '../../export/export';
import '../../css/default.css';
import './section09.css';
import '../../App.css';
import hicoin_qt from '../../file/HiCoin-Qt.exe';

function Section09() {
    const { t } = useTranslation();

    return (
        <>
            <div className='section09 padding-top-bottom-7' style={{backgroundColor: 'black'}} data-aos="fade-up" data-aos-duration="2300">
                <div className='max-width-1400 margin-auto'>
                    <h1 className='sec09-title text-white font-40 font-weight-bold'>{t('section09.title')}</h1>
                    <div className='sec09-content-box display-flex width-100 align-items-center justify-content-center margin-top-3'>
                        <a href={`${hicoin_qt}`} download><div className='contents' style={{width: '282px'}}><img className='contnet-img01' src={sec07_icon01} /></div></a>
                        <a href='https://github.com/hicoindev/hicoin'><div className='contents' style={{width: '282px'}}><img className='contnet-img02' src={sec07_icon02} /></div></a>
                        <a href='https://play.google.com/store/apps/details?id=com.xhicoin.wallet'><div className='contents' style={{width: '282px'}}><img className='contnet-img03' src={sec07_icon03} /></div></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Section09