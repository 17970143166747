import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { sec03_img, 
        mobile_sec04, 
        sec04_en_img, 
        sec04_en_mobile_img,
        sec04_cn_img,
        sec04_cn_mobile_img, 
        sec04_jp_img,
        sec04_jp_mobile_img
} from '../../export/export';
import '../../css/default.css';
import './section04.css';
import '../../App.css';

function Section04() {
    const { t } = useTranslation();
    let language = localStorage.getItem('language');
    console.log('section04', language);
    
    return (
        <>
            <div className='section04 padding-top-bottom-9' id='04' data-aos="fade-up" data-aos-duration="2300">
                <div className='max-width-1400 margin-auto'>
                    <h1 className='sec04-title text-left font-50 font-weight-6 margin-bottom-7'>{t('section04.title')}</h1>
                    <div className='section04-back-box position-relative max-width-1300 margin-auto'>
                        {
                            language === 'ko' || language == null
                            ? 
                                <>
                                    {/* mobile */}
                                    <img className='sec04-img-mobile section04-img width-100 position-relative' src={mobile_sec04}/>
                                    {/* web */}
                                    <img className='sec04-img-web section04-img width-100 position-relative' src={sec03_img} />
                                </>
                            : 
                                language === 'en'
                                ?
                                    <>
                                        {/* mobile */}
                                        <img className='sec04-img-mobile section04-img width-100 position-relative' src={sec04_en_mobile_img}/>
                                        {/* web */}
                                        <img className='sec04-img-web section04-img width-100 position-relative' src={sec04_en_img} />
                                    </>
                                : 
                                    language === 'cn'
                                    ?
                                        <>
                                            {/* mobile */}
                                            <img className='sec04-img-mobile section04-img width-100 position-relative' src={sec04_cn_mobile_img}/>
                                            {/* web */}
                                            <img className='sec04-img-web section04-img width-100 position-relative' src={sec04_cn_img} />
                                        </>
                                    : 
                                        language === 'jp'
                                        ?
                                            <>
                                                {/* mobile */}
                                                <img className='sec04-img-mobile section04-img width-100 position-relative' src={sec04_jp_mobile_img}/>
                                                {/* web */}
                                                <img className='sec04-img-web section04-img width-100 position-relative' src={sec04_jp_img} />
                                            </>
                                        : null
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Section04