import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { sec10_icon01 } from '../../export/export';
import '../../css/default.css';
import './section12.css';
import '../../App.css';
import white_ko from '../../file/HICOIN_WHITEPAPER_KOR.pdf';
import white_en from '../../file/HICOIN_WHITEPAPER_ENG.pdf';
import white_cn from '../../file/HICOIN_WHITEPAPER_CHN.pdf';
import white_jp from '../../file/HICOIN_WHITEPAPER_JP.pdf';

function Section12() {
    const { t } = useTranslation();

    return (
        <>
            <div className='section12 padding-top-bottom-7' id='12' data-aos="fade-up" data-aos-duration="2300">
                <div className='max-width-1400 margin-auto'>
                    <h1 className='sec12-title text-left font-50 font-weight-6 margin-bottom-5'>{t('section12.title')}</h1>
                    <div className='sec12-content-box01 display-flex padding-2'>
                        <div className='doc-en width-100 text-right padding-right-2'><a onClick={() => window.open(`${white_en}`, '_blank')}><button className='whitpaper-btn'>{t('section12.white-en')}</button></a></div>
                        <div className='doc-cn width-100 text-left padding-left-2'><a onClick={() => window.open(`${white_cn}`, '_blank')}><button className='whitpaper-btn'>{t('section12.white-cn')}</button></a></div>
                    </div>
                    <div className='sec12-content-box02 display-flex padding-2 max-width-1200 margin-auto'>
                        <div className='doc-kr width-100 text-left'><a onClick={() => window.open(`${white_ko}`, '_blank')}><button className='whitpaper-btn'>{t('section12.white-ko')}</button></a></div>
                        <div className='doc-jp width-100 text-right'><a onClick={() => window.open(`${white_jp}`, '_blank')}><button className='whitpaper-btn'>{t('section12.white-jp')}</button></a></div>
                    </div>
                    <div className='sec12-doc-img-box width-100'>
                        <img className='sec12-doc-img' src={sec10_icon01} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Section12