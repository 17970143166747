import React, { useEffect } from 'react'
import '../../css/default.css';
import './section02.css';
import '../../App.css';
import AOS from "aos";
import "aos/dist/aos.css";

function Section02() {
    useEffect(() => {
        AOS.init();
    })
    return (
        <>
            <div className='section02' data-aos="fade-up" data-aos-duration="2300">
                {/* mobile */}
                <div className='section02-video sec02-video-mobile width-100 height-50 text-center margin-auto'>
                    <video className='section02-video padding-side-1' autoPlay="autoplay" muted="muted" style={{maxWidth: '100%'}}>
                        <source src={require('../../video/xhicoin_video.mp4')}></source>
                    </video>
                </div>
                {/* web */}
                <div className='section02-video sec02-video-web width-50 height-50 text-center margin-auto'>
                    <video className='section02-video margin-auto' autoPlay="autoplay" muted="muted">
                        <source src={require('../../video/xhicoin_video.mp4')}></source>
                    </video>
                </div>
            </div>
        </>
    )
}

export default Section02