import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { sec05_icon01 } from '../../export/export';
import '../../css/default.css';
import './section06.css';
import '../../App.css';

function Section06() {
    const { t } = useTranslation();

    return (
        <>
            <div className='section06 padding-top-bottom-9' data-aos="fade-up" data-aos-duration="2300">
                <div className='max-width-1400 margin-auto'>
                    <h1 className='sec06-title text-left font-50 font-weight-6 margin-bottom-6'>{t('section06.title')}</h1>
                    <div className='section01-back-box position-relative'>
                        <Row className='sec06-content-box'>
                            <Col className='sec06-contents'>
                                <img className='sec06-content-img' src={sec05_icon01} />
                                <div className='sec06-content-layout padding-side-2'>
                                    <p className='sec06-content-title margin-none font-weight-6 font-20 margin-bottom-1 margin-top-2'>{t('section06.content01-title')}</p>
                                    <p className='sec06-content-text font-16 line-height-25'>{t('section06.content01-text')}</p> 
                                </div>
                            </Col>
                            <Col className='sec06-contents'>
                                <img className='sec06-content-img' src={sec05_icon01} />
                                <div className='sec06-content-layout padding-side-2'>
                                    <p className='sec06-content-title margin-none font-weight-6 font-20 margin-bottom-1 margin-top-2'>{t('section06.content02-title')}</p>
                                    <p className='sec06-content-text font-16 line-height-25'>{t('section06.content02-text')}</p>
                                </div>
                            </Col>
                            <Col className='sec06-contents'>
                                <img className='sec06-content-img' src={sec05_icon01} />
                                <div className='sec06-content-layout padding-side-2'>
                                    <p className='sec06-content-title margin-none font-weight-6 font-20 margin-bottom-1 margin-top-2'>{t('section06.content03-title')}</p>
                                    <p className='sec06-content-text font-16 line-height-25'>{t('section06.content03-text')}</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Section06