import { React, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-scroll/modules";
import { Row, Col } from "react-bootstrap";
import i18lang from '../../lang/i18n';
import {
    logo,
    mobile_menu,
    mobile_menu_logo,
    mobile_menu_close,
    mobile_menu_line
} from "../../export/export";
import "../../css/default.css";
import "./header.css";
import "../../App.css";

function Header() {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();

    const clickHandler = (lang) =>{
        console.log(lang);
        localStorage.setItem('language', lang);
        i18lang.changeLanguage(lang);
    }
    console.log('set lang', localStorage.getItem('language'));

    useEffect(() => {
        if (show === true) {
            // add fadein class name
            document.getElementById("off-canvas").classList.add("off-canvas-fadein");
            // true일 경우 사이드 메뉴 보여짐
            document.getElementById("off-canvas").style.display = "block";
            // true일 경우 body scroll 막기
            document.getElementById("root-body").classList.add("body-fix");
        } else if (show === false) {
            // true일 경우 사이드 메뉴 닫힘
            document.getElementById("off-canvas").style.display = "none";
            // true일 경우 body scroll 활성화
            document.getElementById("root-body").classList.remove("body-fix");
        }
    }, [show]);

    const move = (section) => {
        // true일 경우 사이드 메뉴 닫힘
        document.getElementById("off-canvas").style.display = "none";
        // true일 경우 body scroll 활성화
        document.getElementById("root-body").classList.remove("body-fix");
        // menu status
        setShow(false);
        // 원하는 section으 로 이동
        window.location.href = section;
    };

    useEffect(() => {
        localStorage.setItem('language', 'ko');
    },[]);

    return (
        <>
            {/* Mobild */}
            <div>
                <div className="header-mobile">
                    <Row className="padding-top-bottom-1">
                        <Col className="text-left display-flex align-items-center padding-left-2">
                            <img src={logo} />
                        </Col>
                        <Col className="text-right">
                            <div className="side-bar padding-right-1">
                                <img
                                    onClick={() => {
                                        setShow(!show);
                                    }}
                                    src={mobile_menu}
                                />
                            </div>
                        </Col>
                    </Row>
                    {/* side menu */}
                    <div className="off-canvas" id="off-canvas">
                        <div className="text-right padding-1">
                            <img src={mobile_menu_close} onClick={() => setShow(false)} />
                        </div>
                        <div className="padding-bottom-2">
                            <img className="width-50" src={mobile_menu_logo} />
                        </div>
                        <div>
                            <img className="width-100" src={mobile_menu_line} />
                        </div>
                        <button className="width-100 mobile-menu-btn" onClick={() => { move("#01"); }}>{t('header.menu01')}</button>
                        <button className="width-100 mobile-menu-btn" onClick={() => { move("#03"); }}>{t('header.menu02')}</button>
                        <button className="width-100 mobile-menu-btn" onClick={() => { move("#04"); }}>{t('header.menu03')}</button>
                        <button className="width-100 mobile-menu-btn" onClick={() => { move("#05"); }}>{t('header.menu04')}</button>
                        <button className="width-100 mobile-menu-btn" onClick={() => { move("#07"); }}>{t('header.menu05')}</button>
                        <button className="width-100 mobile-menu-btn" onClick={() => { move("#10"); }}>{t('header.menu06')}</button>
                        <button className="width-100 mobile-menu-btn" onClick={() => { move("#11"); }}>{t('header.menu07')}</button>
                        <button className="width-100 mobile-menu-btn" onClick={() => { move("#12"); }}>{t('header.menu08')}</button>
                        <div className="margin-top-1">
                            <select className="language-select" onChange={(e)=>clickHandler(e.target.value)}>
                                <option value={"ko"}>KO</option>
                                <option value={"en"}>EN</option>
                                <option value={"cn"}>CN</option>
                                <option value={"jp"}>JP</option>
                            </select>
                        </div>
                    </div>
                </div>
                {/* Web */}
                <div className="header-web">
                    <div className="header-box display-flex">
                        <div className="header-inner01 text-right">
                            <img src={logo} />
                        </div>
                        <div className="header-inner02">
                            <Link to="01" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu01')}</button>
                            </Link>
                            <Link to="03" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu02')}</button>
                            </Link>
                            <Link to="04" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu03')}</button>
                            </Link>
                            <Link to="05" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu04')}</button>
                            </Link>
                            <Link to="07" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu05')}</button>
                            </Link>
                            <Link to="10" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu06')}</button>
                            </Link>
                            <Link to="11" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu07')}</button>
                            </Link>
                            <Link to="12" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu08')}</button>
                            </Link>
                        </div>
                        <div className="header-inner03 text-left">
                            <select className="language-select" onChange={(e)=>clickHandler(e.target.value)}>
                                <option value={"ko"}>KO</option>
                                <option value={"en"}>EN</option>
                                <option value={"cn"}>CN</option>
                                <option value={"jp"}>JP</option>
                            </select>
                        </div>
                    </div>
                    {/* <Row className="display-flex padding-top-bottom-1">
                        <Col className="top-logo">
                            <img src={logo} />
                        </Col>
                        <Col xs={6} className="top-menu text-center width-100 text-right">
                            <Link to="01" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu01')}</button>
                            </Link>
                            <Link to="03" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu02')}</button>
                            </Link>
                            <Link to="04" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu03')}</button>
                            </Link>
                            <Link to="05" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu04')}</button>
                            </Link>
                            <Link to="07" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu05')}</button>
                            </Link>
                            <Link to="10" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu06')}</button>
                            </Link>
                            <Link to="11" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu07')}</button>
                            </Link>
                            <Link to="12" spy={true} smooth={true}>
                                <button className="header-menu height-100">{t('header.menu08')}</button>
                            </Link>
                        </Col>
                        <Col className="display-flex align-items-center justify-content-center">
                            <select className="language-select" onChange={(e)=>clickHandler(e.target.value)}>
                                <option value={"ko"}>KO</option>
                                <option value={"en"}>EN</option>
                                <option value={"cn"}>CN</option>
                                <option value={"jp"}>JP</option>
                            </select>
                        </Col>
                    </Row> */}
                </div>
            </div>
        </>
    );
}

export default Header;
