import React from 'react';
import { useTranslation } from 'react-i18next';
import { sec01_back, sec01_symbol, mobile_sec01_back } from '../../export/export';
import '../../css/default.css';
import './section01.css';
import '../../App.css';

function Section01() {

  const { t } = useTranslation();

  return (
    <>
        <div className='section01' data-aos="fade-up" data-aos-duration="2300">
            <div className='section01-back-box position-relative'>
                {/* mobile */}
                <img className='sec01-back-mobile background-img width-100 position-relative' src={mobile_sec01_back}/>
                {/* web */}
                <img className='sec01-back-web background-img width-100 position-relative' src={sec01_back}/>
                <div className='sec01-content-box position-absolute'>
                    <img src={sec01_symbol} />
                    <div className='sec01-text-box text-center margin-auto'>
                      {/* mobile */}
                      <div className='sec01-contents-mobile'>
                        <p className='text-white margin-top-2 margin-none font-30 font-weight-6'>{t('section01.title01')}</p>
                        <p className='text-white margin-bottom-1 font-30 font-weight-6'>{t('section01.title02')}</p>
                        <p className='text-white font-16 font-weight-6 margin-auto width-80'>{t('section01.content')}</p>
                      </div>

                      {/* web */}
                      <div className='sec01-contents-web'>
                        <h1 className='text-white margin-top-2 margin-bottom-1'>HICOIN currently has 58,136</h1>
                        <h3 className='font-22 text-white'>{t('section01.content')}</h3>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Section01