import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { sec09_back, moblie_sec11_back } from '../../export/export';
import { Accordion } from 'react-bootstrap';
import '../../css/default.css';
import './section11.css';
import '../../App.css';

function Section11() {
    const { t } = useTranslation();

    return (
        <>
            <div className='section11' id='11' data-aos="fade-up" data-aos-duration="2300">
                <div className='section11-back-box position-relative'>
                    {/* mobile */}
                    <img className='sec11-back-mobile background-img width-100 position-relative' src={moblie_sec11_back}/>
                    {/* web */}
                    <img className='sec11-back-web background-img width-100 position-relative' src={sec09_back}/>
                    <div className='section11-box position-absolute max-width-1400'>
                    <h1 className='sec11-title text-left font-50 font-weight-6 margin-bottom-5'>{t('section11.title')}</h1>
                        <div className='text-center margin-auto max-width-1300'>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className='accordion-header padding-05 margin-bottom-2'><p className='sec11-content-title font-26 font-weight-6 margin-none'>{t('section11.content01-title')}</p></Accordion.Header>
                                    <Accordion.Body className='text-left'>
                                        <p>{t('section11.content01-text01')}</p>
                                        <p>{t('section11.content01-text02')}<a href="https://bitcointalk.org/index.php?topic=1845717">{t('section11.content01-text03')}</a></p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className='padding-05 margin-bottom-2'><p className='sec11-content-title font-26 font-weight-6 margin-none'>{t('section11.content02-title')}</p></Accordion.Header>
                                    <Accordion.Body className='text-left'>
                                        <p>{t('section11.content02-text')}</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header className='padding-05 margin-bottom-2'><p className='sec11-content-title font-26 font-weight-6 margin-none'>{t('section11.content03-title')}</p></Accordion.Header>
                                    <Accordion.Body className='text-left'>
                                        <p>{t('section11.content03-text')}</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header className='padding-05 margin-bottom-2'><p className='sec11-content-title font-26 font-weight-6 margin-none'>{t('section11.content04-title')}</p></Accordion.Header>
                                    <Accordion.Body className='text-left'>
                                        <p>{t('section11.content04-text')}</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header className='padding-05 margin-bottom-2'><p className='sec11-content-title font-26 font-weight-6 margin-none'>{t('section11.content05-title')}</p></Accordion.Header>
                                    <Accordion.Body className='text-left'>
                                        <p>{t('section11.content05-text')}</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header className='padding-05 margin-bottom-2'><p className='sec11-content-title font-26 font-weight-6 margin-none'>{t('section11.content06-title')}</p></Accordion.Header>
                                    <Accordion.Body className='text-left'>
                                        <p>{t('section11.content06-text01')}</p>
                                        <p>{t('section11.content06-text02')}</p>
                                        <p>{t('section11.content06-text03')}</p>
                                        <p>{t('section11.content06-text04')}</p>
                                        <p>{t('section11.content06-text05')}</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Section11