import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { sec02_back, sec02_icon01, sec02_icon02, sec02_icon03, mobile_sec03_back } from '../../export/export';
import AOS from "aos";
import "aos/dist/aos.css";
import '../../css/default.css';
import './section03.css';
import '../../App.css';

function Section03() {
    const { t } = useTranslation();

    useEffect(() => {
        AOS.init();
    })
    return (
        <>
        <div className='section03' id='03' data-aos="fade-up" data-aos-duration="2300">
            <div className='section01-back-box position-relative'>
                {/* mobile */}
                <img className='sec03-back-mobile background-img width-100 position-relative' src={mobile_sec03_back}/>
                {/* web */}
                <img className='sec03-back-web background-img width-100 position-relative' src={sec02_back}/>

                <div className='sec03-contents-box position-absolute max-width-1400'>
                    <Row className='max-width-1300 margin-auto'>
                        <Col className='sec03-content-box' sm={7}>
                            <div className='sec03-title-box text-left margin-bottom-5'>
                                <h1 className='sec03-title text-yellow font-weight-6 line-height-18 font-50'>{t('section03.title')}</h1>
                                <h3 className='sec03-text font-22 font-weight-6 line-height-18'>​{t('section03.sub-title')}</h3>
                            </div>
                            <div className='sec03-icon-box display-flex margin-bottom-2'>
                                <div className='sec02-icon01-box margin-right-3 display-flex align-items-center'><img src={sec02_icon01} /></div>
                                <div className='sec02-text-box text-left'>
                                    <h3 className='font-20 font-weight-6 line-height-2'>{t('section03.content01-title')}</h3>
                                    <p>{t('section03.content01-text')}</p>
                                </div>
                            </div>
                            <div className='sec03-icon-box display-flex margin-bottom-2'>
                                <div className='sec02-icon01-box margin-right-3 display-flex align-items-center'><img src={sec02_icon02} /></div>
                                <div className='sec02-text-box text-left'>
                                    <h3 className='font-20 font-weight-6 line-height-2'>{t('section03.content02-title')}</h3>
                                    <p>{t('section03.content02-text')}</p>
                                </div>
                            </div>
                            <div className='sec03-icon-box display-flex margin-bottom-2'>
                                <div className='sec02-icon01-box margin-right-3 display-flex align-items-center'><img src={sec02_icon03} /></div>
                                <div className='sec02-text-box text-left'>
                                    <h3 className='font-20 font-weight-6 line-height-2'>{t('section03.content03-title')}</h3>
                                    <p>{t('section03.content03-text')}</p>
                                </div>
                            </div>
                        </Col>
                        <Col sm={4}></Col>
                    </Row>
                </div>
            </div>
        </div>
        </>
    )
}

export default Section03