import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { sec08_icon01, 
        sec08_icon02, 
        sec08_icon03, 
        sec10_en_img01, 
        sec10_en_img02, 
        sec10_en_img03,
        sec10_cn_img01,
        sec10_cn_img02,
        sec10_cn_img03,
        sec10_jp_img01,
        sec10_jp_img02,
        sec10_jp_img03
} from '../../export/export';
import '../../css/default.css';
import './section10.css';
import '../../App.css';

function Section10() {
    const { t } = useTranslation();
    let language = localStorage.getItem('language');
    console.log('section10', language);

    return (
        <>
            <div className='section10 padding-top-7' id='10' data-aos="fade-up" data-aos-duration="2300">
                <div className='max-width-1400 margin-auto'>
                    <h1 className='sec10-title text-left font-50 font-weight-6 margin-bottom-6'>{t('section10.title')}</h1>
                    <div className='sec10-content-box display-flex width-100 align-items-center justify-content-center'>
                    {
                        language === 'ko' || language === null
                        ?
                            <>
                                <div className='map-img-box margin-right-3'><img className='map-icon' src={sec08_icon01} /></div>
                                <div className='map-img-box'><img className='map-icon' src={sec08_icon02} /></div>
                                <div className='map-img-box margin-left-3'><img className='map-icon' src={sec08_icon03} /></div>
                            </>
                        :
                            language === 'en'
                            ?
                                <>
                                    <div className='map-img-box margin-right-3'><img className='map-icon' src={sec10_en_img01} /></div>
                                    <div className='map-img-box'><img className='map-icon' src={sec10_en_img02} /></div>
                                    <div className='map-img-box margin-left-3'><img className='map-icon' src={sec10_en_img03} /></div>
                                </>
                            :
                                language === 'cn'
                                ?
                                    <>
                                        <div className='map-img-box margin-right-3'><img className='map-icon' src={sec10_cn_img01} /></div>
                                        <div className='map-img-box'><img className='map-icon' src={sec10_cn_img02} /></div>
                                        <div className='map-img-box margin-left-3'><img className='map-icon' src={sec10_cn_img03} /></div>
                                    </>
                                :
                                    language === 'jp'
                                    ?
                                        <>
                                            <div className='map-img-box margin-right-3'><img className='map-icon' src={sec10_jp_img01} /></div>
                                            <div className='map-img-box'><img className='map-icon' src={sec10_jp_img02} /></div>
                                            <div className='map-img-box margin-left-3'><img className='map-icon' src={sec10_jp_img03} /></div>
                                        </>
                                    : null
                    }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Section10