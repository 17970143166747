import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { sec04_back, sec04_icon01, sec04_icon02, sec04_icon03, sec04_icon04, sec04_icon05, sec04_icon06, sec04_icon07,
    sec04_icon08, sec04_icon09, sec04_icon10, mobile_sec05_back} from '../../export/export';
import '../../css/default.css';
import './section05.css';
import '../../App.css';

function Section05() {
    const { t } = useTranslation();
    let language = localStorage.getItem('language');
    console.log('section05', language);

    return (
        <>
            <div className='section05 padding-top-bottom-9' id='05' data-aos="fade-up" data-aos-duration="2300">
                <div className='max-width-1400 margin-auto position-relative'>
                    <h1 className='sec05-title text-left font-50 font-weight-6 margin-bottom-7'>{t('section05.title')}</h1>
                    <div className='section01-back-box position-relative'>
                        {/* mobile */}
                        <img className='section05-img sec05-back-mobile width-100 position-relative display-none' src={mobile_sec05_back}/>
                        {/* web */}
                        <img className='section05-img sec05-back-web width-100 position-relative' src={sec04_back}/>
                        <div className='contents-layout position-absolute width-70'>
                            <Row className='content-line-box'>
                                <Col>
                                    <Row className='height-100'>
                                        <Col className='padding-top-bottom-1 margin-05 sec05-content-box'>
                                            <div className='display-flex align-items-center justify-content-center margin-bottom-05 height-50'>
                                                <img src={sec04_icon01} />
                                            </div>
                                            <div className='padding-bottom-1 padding-side-05'>
                                                <p className='content-title font-weight-6 font-22 margin-none'>{t('section05.content01-title')}</p>
                                                {
                                                    language == 'en'
                                                    ?
                                                        <>
                                                            <p className='content-text-en margin-none font-14 font-weight-6'>{t('section05.content01-text01')}</p>
                                                            <p className='content-text-en margin-none margin-bottom-1 font-12 font-weight-6'>{t('section05.content01-text02')}</p>
                                                        </>
                                                    :
                                                        <>
                                                            <p className='content-text margin-none font-weight-6'>{t('section05.content01-text01')}</p>
                                                            <p className='content-text margin-none margin-bottom-1 font-14 font-weight-6'>{t('section05.content01-text02')}</p>
                                                        </>
                                                }

                                            </div>
                                        </Col>
                                        <Col className='padding-top-bottom-1 margin-05 sec05-content-box'>
                                            <div className='display-flex align-items-center justify-content-center margin-bottom-1 height-50'>
                                                <img src={sec04_icon02} />
                                            </div>
                                            <div>
                                                <p className='content-title font-weight-6 font-22 margin-none'>{t('section05.content02-title')}</p>
                                                <p className='margin-none font-weight-6'>{t('section05.content02-text')}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className='height-100'>
                                        <Col className='padding-top-bottom-1 margin-05 sec05-content-box'>
                                            <div className='display-flex align-items-center justify-content-center margin-bottom-1 height-50'>
                                                <img src={sec04_icon03} />
                                            </div>
                                            <div>
                                                <p className='content-title font-weight-6 font-22 margin-none'>{t('section05.content03-title')}</p>
                                                <p className='margin-none font-weight-6'>{t('section05.content03-text')}</p>
                                            </div>
                                        </Col>
                                        <Col className='padding-top-bottom-1 margin-05 sec05-content-box'>
                                            <div className='display-flex align-items-center justify-content-center margin-bottom-1 height-50'>
                                                <img src={sec04_icon04} />
                                            </div>
                                            <div>
                                                <p className='content-title font-weight-6 font-22 margin-none'>{t('section05.content04-title')}</p>
                                                <p className='margin-none font-weight-6'>{t('section05.content04-text')}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='margin-bottom-1 content-line-box sec05-line-box02'>
                                <Col>
                                    <Row className='height-100'>
                                        <Col className='padding-top-bottom-1 margin-05 sec05-content-box height-100'>
                                            <div className='display-flex align-items-center justify-content-center margin-bottom-1 height-50'>
                                                <img src={sec04_icon05} />
                                            </div>
                                            <div>
                                                <p className='content-title font-weight-6 font-22 margin-none'>{t('section05.content05-title')}</p>
                                                <p className='margin-none font-weight-6'>{t('section05.content05-text')}</p>
                                            </div>
                                        </Col>
                                        <Col className='padding-top-bottom-1 margin-05 sec05-content-box'>
                                            <div className='display-flex align-items-center justify-content-center margin-bottom-1 height-50'>
                                                <img src={sec04_icon06} />
                                            </div>
                                            <div>
                                                <p className='content-title font-weight-6 font-22 margin-none'>{t('section05.content06-title')}</p>
                                                <p className='margin-none font-weight-6'>{t('section05.content06-text')}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className='height-100'>
                                        <Col className='padding-top-bottom-1 margin-05 sec05-content-box height-100'>
                                            <div className='display-flex align-items-center justify-content-center margin-bottom-1 height-50'>
                                                <img src={sec04_icon07} />
                                            </div>
                                            <div>
                                                <p className='content-title font-weight-6 font-22 margin-none'>{t('section05.content07-title')}</p>
                                                <p className='margin-none font-weight-6'>{t('section05.content07-text01')}</p>
                                                <p className='margin-none font-weight-6'>{t('section05.content07-text02')}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='content-line-box'>
                                <Col>
                                    <Row className='height-100'>
                                        <Col className='padding-top-bottom-1 margin-05 sec05-content-box height-100'>
                                            <div className='display-flex align-items-center justify-content-center margin-bottom-1 height-50'>
                                                <img src={sec04_icon08} />
                                            </div>
                                            <div>
                                                <p className='content-title font-weight-6 font-22 margin-none'>{t('section05.content08-title')}</p>
                                                <p className='margin-none font-weight-6'>{t('section05.content08-text')}</p>
                                            </div>
                                        </Col>
                                        <Col className='padding-top-bottom-1 margin-05 sec05-content-box'>
                                            <div className='display-flex align-items-center justify-content-center margin-bottom-1 height-50'>
                                                <img src={sec04_icon09} />
                                            </div>
                                            <div>
                                                <p className='content-title font-weight-6 font-22 margin-none'>{t('section05.content09-title')}</p>
                                                <p className='margin-none font-weight-6'>{t('section05.content09-text01')}</p>
                                                <p className='margin-none font-weight-6'>{t('section05.content09-text02')}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className='height-100'>
                                        <Col className='padding-top-bottom-1 margin-05 sec05-content-box height-100'>
                                            <div className='display-flex align-items-center justify-content-center margin-bottom-1 height-50'>
                                                <img src={sec04_icon09} />
                                            </div>
                                            <div>
                                                <p className='content-title font-weight-6 font-22 margin-none'>{t('section05.content10-title')}</p>
                                                <p className='margin-none font-weight-6'>{t('section05.content10-text01')}</p>
                                                <p className='margin-none font-weight-6'>{t('section05.content10-text02')}</p>
                                            </div>
                                        </Col>
                                        <Col className='padding-top-bottom-1 margin-05 sec05-content-box'>
                                            <div className='display-flex align-items-center justify-content-center margin-bottom-1 height-50'>
                                                <img src={sec04_icon10} />
                                            </div>
                                            <div>
                                                <p className='content-title font-weight-6 font-22 margin-none'>{t('section05.content11-title')}</p>
                                                <p className='margin-none font-weight-6'>{t('section05.content11-text')}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Section05