import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import TranslationEn from "./translation.en.json";
import TranslationKo from "./translation.ko.json";
import TranslationCn from "./translation.cn.json";
import TranslationJp from "./translation.jp.json";

const resource = {
    ko: {
        translations: TranslationKo
    },
    en: {
        translations: TranslationEn
    },
    cn: {
        translations: TranslationCn
    },
    jp: {
        translations: TranslationJp
    }
}

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources:resource,
        // 초기 설정 언어
        lng: "ko",
        fallbackLng: "ko",
        debug: true,
        defaultNS: "translations",
        ns: "translations",
        keySeparator: '.',
        interpolation: {
            escapeValue: false
        }
    })

export default i18n;