import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { sec06_icon01, 
        sec06_icon02, 
        sec06_icon03, 
        sec06_icon04, 
        sec06_icon05, 
        sec06_icon06, 
        sec06_icon07, 
        sec06_icon08, 
        sec06_icon09, 
        sec06_icon10, 
        sec06_icon11,
        sec08_en_guide,
        sec08_cn_guide,
        sec08_jp_guide
} from '../../export/export';
import '../../css/default.css';
import './section08.css';
import '../../App.css';
import guide_ko from '../../file/wallet_Guide_ko.pdf';
import guide_en from '../../file/wallet_Guide_en.pdf';
import guide_cn from '../../file/wallet_Guide_cn.pdf';
import guide_jp from '../../file/wallet_Guide_jp.pdf';

function Section08() {
    const { t } = useTranslation();
    let language = localStorage.getItem('language');
    console.log('section08', language);

    return (
        <>
            <div className='section08 padding-top-9 padding-bottom-5' data-aos="fade-up" data-aos-duration="2300">
                <div className='max-width-1400 margin-auto'>
                    <div className='display-flex margin-bottom-3 sec08-transition-box'>
                        <div className='sec08-img-box margin-right-1 display-flex align-items-center'><img src={sec06_icon01}/></div>
                        <div className='sec08-content-box text-left display-flex align-items-center jutify-content-center'>
                            <div>
                                <h2 className='sec08-content-title font-20 font-weight-bold'>{t('section08.content01-title')}</h2>
                                <h4 className='sec08-content-text font-16 line-height-25'>​{t('section08.content01-text')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className='display-flex margin-bottom-3 sec08-transition-box'>
                        <div className='sec08-img-box margin-right-1 display-flex align-items-center justify-content-center'><img src={sec06_icon02}/></div>
                        <div className='sec08-content-box text-left display-flex align-items-center jutify-content-center'>
                            <div>
                                <h2 className='sec08-content-title font-20 font-weight-bold'>{t('section08.content02-title')}</h2>
                                <h4 className='sec08-content-text font-16 line-height-25'>{t('section08.content02-text')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className='display-flex margin-bottom-3 sec08-transition-box'>
                        <div className='sec08-img-box margin-right-1 display-flex align-items-center justify-content-center'><img src={sec06_icon03}/></div>
                        <div className='sec08-content-box text-left display-flex align-items-center jutify-content-center'>
                            <div>
                                <h2 className='sec08-content-title font-20 font-weight-bold'>​​{t('section08.content03-title')}</h2>
                                <h4 className='sec08-content-text font-16 line-height-25'>​​{t('section08.content03-text')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className='display-flex margin-bottom-3 sec08-transition-box'>
                        <div className='sec08-img-box margin-right-1 display-flex align-items-center justify-content-center'><img src={sec06_icon04}/></div>
                        <div className='sec08-content-box text-left display-flex align-items-center jutify-content-center'>
                            <div>
                                <h2 className='sec08-content-title font-20 font-weight-bold'>​​{t('section08.content04-title')}</h2>
                                <h4 className='sec08-content-text font-16 line-height-25'>​{t('section08.content04-text01')}<br/>{t('section08.content04-text02')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className='display-flex margin-bottom-3 sec08-transition-box'>
                        <div className='sec08-img-box margin-right-1 display-flex align-items-center justify-content-center'><img src={sec06_icon05}/></div>
                        <div className='sec08-content-box text-left display-flex align-items-center jutify-content-center'>
                            <div>
                                <h2 className='sec08-content-title font-20 font-weight-bold'>​​​{t('section08.content05-title')}</h2>
                                <h4 className='sec08-content-text font-16 line-height-25'>​​​​{t('section08.content05-text')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className='display-flex margin-bottom-3 sec08-transition-box'>
                        <div className='sec08-img-box margin-right-1 display-flex align-items-center justify-content-center'><img src={sec06_icon06}/></div>
                        <div className='sec08-content-box text-left display-flex align-items-center jutify-content-center'>
                            <div>
                                <h2 className='sec08-content-title font-20 font-weight-bold'>​​{t('section08.content06-title')}</h2>
                                <h4 className='sec08-content-text font-16 line-height-25'>​​{t('section08.content06-text')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className='display-flex margin-bottom-3 sec08-transition-box'>
                        <div className='sec08-img-box margin-right-1 display-flex align-items-center justify-content-center'><img src={sec06_icon07}/></div>
                        <div className='sec08-content-box text-left display-flex align-items-center jutify-content-center'>
                            <div>
                                <h2 className='sec08-content-title font-20 font-weight-bold'>​​{t('section08.content07-title')}</h2>
                                <h4 className='sec08-content-text font-16 line-height-25'>​​​{t('section08.content07-text')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className='display-flex margin-bottom-3 sec08-transition-box'>
                        <div className='sec08-img-box margin-right-1 display-flex align-items-center justify-content-center'><img src={sec06_icon08}/></div>
                        <div className='sec08-content-box text-left display-flex align-items-center jutify-content-center'>
                            <div>
                                <h2 className='sec08-content-title font-20 font-weight-bold'>​​​​{t('section08.content08-title')}</h2>
                                <h4 className='sec08-content-text font-16 line-height-25'>​​{t('section08.content08-text')}</h4>
                            </div>
                        </div>
                    </div>
                    <div className='display-flex sec08-transition-box'>
                        <div className='sec08-img-box margin-right-1 display-flex align-items-center justify-content-center'><img src={sec06_icon09}/></div>
                        <div className='sec08-content-box text-left display-flex align-items-center jutify-content-center'>
                            <div>
                                <h2 className='sec08-content-title font-20 font-weight-bold'>​​​​{t('section08.content09-title')}</h2>
                                <h4 className='sec08-content-text font-16 line-height-25'>​​​{t('section08.content09-text')}</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='max-width-1500 display-flex justify-content-right align-items-center margin-auto margin-top-4'>
                    {
                        language == 'ko' || language == null
                        ?
                            <>
                                {/* <a className='guide-book-tag' onClick={() => window.open(`${pdf}`, 'dd', '<link href="' + window.location.protocol + '//' + window.location.host + '/favicon.png" rel="icon" type="image/x-icon">', '_blank')}> */}
                                <a className='guide-book-tag' onClick={() => window.open(`${guide_ko}`, '_blank')}>
                                    <img className='guide-book' src={sec06_icon10} />
                                </a>
                            </>
                        :
                            language == 'en'
                            ?
                                <>
                                    <a className='guide-book-tag' onClick={() => window.open(`${guide_en}`, '_blank')}>
                                        <img className='guide-book' src={sec08_en_guide} />
                                    </a>
                                </>
                            :
                                language == 'cn'
                                ?
                                    <>
                                        <a className='guide-book-tag' onClick={() => window.open(`${guide_cn}`, '_blank')}>
                                            <img className='guide-book' src={sec08_cn_guide} />
                                        </a>
                                    </>
                                :
                                    language == 'jp'
                                    ?
                                        <>
                                            <a className='guide-book-tag' onClick={() => window.open(`${guide_jp}`, '_blank')}>
                                                <img className='guide-book' src={sec08_jp_guide} />
                                            </a>
                                        </>
                                    :null
                    }

                </div>
            </div>
        </>
    )
}

export default Section08